import { Info } from "../alert";
import { Link } from "../link";

type PrivatelinkIntegration =
  | "aws-privatelink"
  | "gcp-psc"
  | "azure-privatelink";

export interface WhitelistDetailsOpts {
  target: string;

  privatelink?: {
    supportedIntegrations: PrivatelinkIntegration[];
    target?: string;
    anchor?: string;
  };
}

export const WhitelistDetails = (opts?: WhitelistDetailsOpts) => {
  const { target = "warehouse" } = opts;

  const supportedIntegrations: PrivatelinkIntegration[] =
    opts?.privatelink?.supportedIntegrations || [];
  const supportedPrivateLinks = supportedIntegrations
    .map((integration) => {
      switch (integration) {
        case "aws-privatelink":
          return {
            name: "AWS PrivateLink",
            href: `/security/aws/privatelink`,
          };

        case "gcp-psc":
          return {
            name: "GCP Private Service Connect",
            href: `/security/gcp/private-service-connect`,
          };

        case "azure-privatelink":
          return {
            name: "Azure Private Link",
            href: `/security/azure/private-link`,
          };

        default:
          console.error("Unsupported integration: ", integration);
          return;
      }
    })
    .filter((v) => v !== undefined);

  return (
    <Info>
      <p>
        You may need to allowlist Hightouch's IP addresses to let our systems
        connect to your {target}. Reference our{" "}
        <Link variant="inline" href="/security/networking/#ip-addresses">
          networking docs
        </Link>{" "}
        to determine which IP addresses you need to allowlist.
      </p>

      {supportedPrivateLinks.length > 0 && (
        <p>
          <br />
          You can also securely connect to your{" "}
          {opts.privatelink.target ?? target} using{" "}
          {renderPrivatelinks(supportedPrivateLinks, opts.privatelink.anchor)}.{" "}
          {renderBusinessTierFeatures(supportedPrivateLinks.map((v) => v.name))}
        </p>
      )}
    </Info>
  );
};

const renderPrivatelinks = (
  pls: { name: string; href: string }[],
  anchor?: string
) => {
  const toLink = ({ name, href }: { name: string; href: string }) => (
    <Link variant="inline" href={anchor ? `${href}#${anchor}` : href}>
      {name}
    </Link>
  );

  if (pls.length === 1) {
    return <>{toLink(pls[0])}</>;
  } else if (pls.length === 2) {
    return (
      <>
        {toLink(pls[0])} or {toLink(pls[1])}
      </>
    );
  } else {
    // return pls.slice(0, -1).map(toLink).join(", ") + ", or " + toLink(pls.slice(-1)[0]);

    return (
      <>
        {pls.slice(0, -1).map((pl) => (
          <>{toLink(pl)}, </>
        ))}
        {" or "}
        {toLink(pls.slice(-1)[0])}
      </>
    );
  }
};

const renderBusinessTierFeatures = (arr: string[]) => {
  if (arr.length === 1) {
    return (
      <>
        {arr[0]} is a{" "}
        <Link variant="inline" href="https://hightouch.com/pricing">
          Business Tier
        </Link>{" "}
        feature.
      </>
    );
  } else if (arr.length === 2) {
    return (
      <>
        {arr[0]} and {arr[1]} are{" "}
        <Link variant="inline" href="https://hightouch.com/pricing">
          Business Tier
        </Link>{" "}
        features.
      </>
    );
  } else {
    return (
      <>
        {arr.slice(0, -1).join(", ") + ", and " + arr.slice(-1)} are{" "}
        <Link variant="inline" href="https://hightouch.com/pricing">
          Business Tier
        </Link>{" "}
        features.
      </>
    );
  }
};
