import { Button, Flex } from "@chakra-ui/react";
import { VFC } from "react";

import { User } from "api/get-user";
import { SearchBox } from "components/commandbar/search/search-box";
import { CTALinks } from "components/cta-links";
import { track } from "lib/analytics";
import { Link } from "ui";
import { MenuIcon, CloseIcon } from "ui/icons";
import { Logo } from "ui/logo";

type Props = {
  loadingUser: boolean;
  user?: User;
  navOpen?: boolean;
  toggleNav?: () => void;
};

export const Topbar: VFC<Readonly<Props>> = ({
  user,
  toggleNav,
  navOpen,
  loadingUser,
}) => {
  return (
    <Flex
      pos="sticky"
      top={0}
      left={0}
      as="header"
      align="center"
      justify="center"
      bg="white"
      height="80px"
      borderBottom="1px"
      borderColor="base.divider"
      zIndex={3}
    >
      <Flex
        maxWidth="1600px"
        px={{ base: 4, md: 6 }}
        flex={1}
        justify="space-between"
        align="center"
      >
        <Flex align="center" flex={1} mr={4}>
          <Link
            aria-label="Hightouch Docs Home"
            href="/"
            mr={4}
            display={["none", "none", "block"]}
          >
            <Logo width="auto" height="20px" />
          </Link>
          <SearchBox />
        </Flex>

        <Button
          display={["flex", "flex", "none"]}
          variant="subtle"
          ml="auto"
          onClick={toggleNav}
        >
          {navOpen ? <CloseIcon boxSize={6} /> : <MenuIcon boxSize={6} />}
        </Button>
        <Flex
          display={{ base: "none", md: "flex" }}
          alignItems="end"
          mt={"auto"}
          gap={3}
        >
          {user ? (
            <Flex align="center" gap={3}>
              <Link
                href="https://changelog.hightouch.io/"
                fontSize="md"
                variant="secondary"
                onClick={() => track("Changelog Clicked")}
              >
                Changelog
              </Link>
              <Button
                onClick={() => {
                  window.location.href = "https://app.hightouch.com";
                  track("Dashboard Button Clicked");
                }}
              >
                Go to app
              </Button>
            </Flex>
          ) : (
            <CTALinks primary="signup" includeChangelog hideSecondary />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
