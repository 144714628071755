import {
  LinkProps as ChakraLinkProps,
  Link as ChakraLink,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { FC, forwardRef, MouseEventHandler, Ref, RefAttributes } from "react";

export type LinkProps = {
  href?: string;
  newTab?: boolean;
  /** @default: "primary" */
  variant?: "primary" | "secondary" | "inline" | "subtle";
  onClick?: MouseEventHandler;
  onMouseMove?: MouseEventHandler;
} & RefAttributes<HTMLAnchorElement> &
  Omit<
    ChakraLinkProps,
    "href" | "target" | "rel" | "variant" | "textDecoration"
  >;

export const Link: FC<Readonly<LinkProps>> = forwardRef(
  (
    { href, newTab, children, variant = "primary", ...props },
    ref: Ref<HTMLAnchorElement>
  ) => {
    const LinkElement = (
      <ChakraLink
        ref={ref}
        href={href}
        target={newTab ? "_blank" : undefined}
        rel={newTab ? "noreferrer noopener" : undefined}
        variant={variant}
        {...props}
      >
        {children}
      </ChakraLink>
    );

    if (href?.startsWith("/")) {
      return (
        <NextLink passHref href={href}>
          {LinkElement}
        </NextLink>
      );
    }

    return LinkElement;
  }
);

Link.displayName = "Link";
