import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "base",
    fontWeight: "semibold",
    lineHeight: "0",
    transition:
      "color 150ms ease-in-out, background-color 150ms ease-in-out, border-color 150ms ease-in-out",
    _focus: {
      outline: "none !important",
    },
    _focusVisible: {
      outline: "none !important",
      boxShadow: "outline",
    },
    _disabled: {
      pointerEvents: "none",
    },
  },
  sizes: {
    sm: {
      px: 3,
      fontSize: "sm",
      height: "24px",
    },
    md: {
      px: 4,
      height: "32px",
      fontSize: "md",
    },
    lg: {
      fontSize: "lg",
      px: 6,
      height: "40px",
    },
  },
  variants: {
    primary: {
      color: "white",
      bg: "primary.base",
      _hover: {
        bg: "primary.hover",
      },
      _active: {
        bg: "primary.pressed",
      },
    },
    secondary: {
      bg: "white",
      border: "1px",
      color: "text.primary",
      boxShadow: "sm",
      borderColor: "base.border",
      svg: {
        color: "text.secondary",
        transition: "color 150ms ease-in-out",
      },
      _hover: {
        color: "text.primary",
        borderColor: "gray.400",
        bg: "white",
        svg: { color: "text.primary" },
      },
      _active: {
        color: "text.primary",
        borderColor: "gray.500",
        bg: "white",
        svg: { color: "text.primary" },
      },
      _disabled: {
        color: "text.disabled",
        bg: "white",
        filter: "none",
        svg: { color: "text.disabled" },
      },
    },
    subtle: {
      p: 0,
      color: "text.tertiary",
      _hover: {
        color: "text.primary",
      },
    },
    inlineLink: {
      p: 0,
      fontSize: "inherit",
      lineHeight: "inherit",
      display: "inline-block",
      textDecoration: "underline",
      textUnderlineOffset: "4px",
      color: "link.default",
      _hover: {
        color: "link.hover",
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "primary",
  },
};
