import { Icon, IconProps } from "@chakra-ui/react";

export function ChevronRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M8.293 6.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L12.586 12 8.293 7.707a1 1 0 0 1 0-1.414Z"
        clipRule="evenodd"
      />
    </Icon>
  );
}
