import { Flex, Box, Icon } from "@chakra-ui/react";
import { FC } from "react";

import { InfoIcon, StarIcon, WarningIcon } from "ui/icons";

type MessageProps = {
  variant?: "info" | "tip" | "warning";
};

const variants = {
  info: {
    borderColor: "gray.400",
    bg: "base.background",
  },
  tip: {
    borderColor: "teal.border",
    bg: "teal.background",
  },
  warning: {
    borderColor: "warning.border",
    bg: "warning.background",
  },
};

const icons = {
  info: <InfoIcon mr={3} boxSize={6} flexShrink={0} color="text.secondary" />,
  warning: (
    <WarningIcon mr={3} boxSize={6} flexShrink={0} color="warning.base" />
  ),
  tip: <StarIcon mr={3} boxSize={6} flexShrink={0} color="teal.base" />,
};

export const Alert: FC<Readonly<MessageProps>> = ({
  children,
  variant = "info",
}) => {
  const icon = icons[variant];

  return (
    <Flex
      border="1px"
      borderRadius="base"
      padding={5}
      my={12}
      fontSize="lg"
      alignItems="start"
      fontWeight="medium"
      {...variants[variant]}
    >
      {icon}
      <Box sx={{ p: { margin: 0 } }}>{children}</Box>
    </Flex>
  );
};

export const Tip = (props) => <Alert variant="tip" {...props} />;

export const Info = (props) => <Alert variant="info" {...props} />;

export const Warning = (props) => <Alert variant="warning" {...props} />;
