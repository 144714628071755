import { Icon, IconProps } from "@chakra-ui/react";

export const PlayIcon = (props: IconProps) => (
  <Icon viewBox="0 0 60 60" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30Zm14.604-29.316a.79.79 0 0 0 0-1.368L23.288 17.01a.79.79 0 0 0-1.184.683v24.614a.79.79 0 0 0 1.184.683l21.316-12.306Z"
      clipRule="evenodd"
    />
  </Icon>
);
