import { Text } from "@chakra-ui/react";
import { connectHighlight } from "react-instantsearch-dom";

export const Highlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  });

  return (
    <>
      {parsedHit.map((part, index) => (
        <>
          {part.isHighlighted ? (
            <Text
              key={index}
              as="mark"
              bg="primary.background"
              color="teal.base"
            >
              {part.value}
            </Text>
          ) : (
            <Text key={index} as="span">
              {part.value}
            </Text>
          )}
        </>
      ))}
    </>
  );
});
