import { Icon, IconProps } from "@chakra-ui/react";

export function BreakdownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M16.667 8c0 .552-.523 1-1.167 1H6.167C5.522 9 5 8.552 5 8s.522-1 1.167-1H15.5c.644 0 1.167.448 1.167 1ZM19 12c0 .552-.522 1-1.167 1H6.167C5.522 13 5 12.552 5 12s.522-1 1.167-1h11.666c.645 0 1.167.448 1.167 1Zm-7 4c0 .552-.522 1-1.167 1H6.167C5.522 17 5 16.552 5 16s.522-1 1.167-1h4.666c.645 0 1.167.448 1.167 1Z"
        clip-rule="evenodd"
      />
    </Icon>
  );
}
