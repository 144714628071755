import { FC } from "react";

import { track } from "lib/analytics";
import { LinkProps, Link } from "ui/link";

interface DownloadProps extends LinkProps {
  fileName: string;
}

export const DownloadLink: FC<Readonly<DownloadProps>> = ({
  fileName,
  href,
  children,
}) => {
  const downloadClicked = (fileName: string) => {
    track("File Downloaded", {
      page: window.location.href,
      name: fileName,
      file: href,
    });
  };

  return (
    <Link
      href={href}
      newTab
      variant="inline"
      onClick={() => downloadClicked(fileName)}
    >
      {children}
    </Link>
  );
};
