import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import * as Components from "./components";
import { typography } from "./typography";
import { scale } from "./scale";

export const theme = extendTheme({
  config: {
    cssVarPrefix: "theme",
  },
  styles: {
    global: {
      html: {
        fontSize: "lg",
      },
      body: {
        fontFamily: "body",
        color: "text.primary",
        width: "100%",
        height: "100%",
        textRendering: "optimizeLegibility",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
      },
    },
  },
  // Space variables that are used for setting margin, padding, gap, etc.
  space: { ...scale },
  // Size variables that are used for setting width, height, boxSize, etc.
  sizes: {
    ...scale,
    max: "max-content",
    min: "min-content",
    full: "100%",
    container: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
  },
  radii: {
    xs: "2px",
    sm: "4px",
    md: "6px",
    lg: "8px",
    xl: "12px",
    base: "6px",
    full: "9999px",
  },
  shadows: {
    none: "none",
    sm: "0px 1px 2px -1px rgba(16, 24, 40, 0.04), 0px 0px 1px 0px rgba(16, 24, 40, 0.06)",
    md: "0px 5px 13px -2px rgba(16, 24, 40, 0.07), 0px 3px 5px -2px rgba(16, 24, 40, 0.04), 0px 0px 1px -2px rgba(16, 24, 40, 0.04)",
    lg: "0px 17px 30px -4px rgba(16, 24, 40, 0.06), 0px 8px 17px -4px rgba(16, 24, 40, 0.06), 0px 3px 8px -4px rgba(16, 24, 40, 0.04), 0px 0px 1px -4px rgba(16, 24, 40, 0.04)",
    outline: "0px 0px 0px 4px #E0F3F5",
    outlineRed: "0 0 0 4px #fee4e2",
  },
  colors,
  components: Components,
  ...typography,
});
