// Implements KBarResults without virtualization to easily handle variable item heights

import { Box } from "@chakra-ui/react";
import { useKBar } from "kbar";
import {
  VFC,
  useEffect,
  useRef,
  useCallback,
  cloneElement,
  MouseEventHandler,
} from "react";
import scrollIntoView from "scroll-into-view-if-needed";

const START_INDEX = 0;

interface RenderParams {
  item: any;
  active: boolean;
  onClick: MouseEventHandler;
  onMouseMove: MouseEventHandler;
}

interface Props {
  items: any[];
  onRender: (params: RenderParams) => React.ReactElement;
}

export const Results: VFC<Readonly<Props>> = ({ items, onRender }) => {
  const activeRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // store a ref to all items so we do not have to pass
  // them as a dependency when setting up event listeners.
  const itemsRef = useRef(items);
  itemsRef.current = items;

  const { query, search, activeIndex, options } = useKBar((state) => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
    activeIndex: state.activeIndex,
  }));

  useEffect(() => {
    const handler = (event) => {
      if (event.key === "ArrowUp" || (event.ctrlKey && event.key === "p")) {
        event.preventDefault();
        query.setActiveIndex((index) => {
          let nextIndex = index > START_INDEX ? index - 1 : index;
          // avoid setting active index on a group
          if (typeof itemsRef.current[nextIndex] === "string") {
            if (nextIndex === 0) return index;
            nextIndex -= 1;
          }
          return nextIndex;
        });
      } else if (
        event.key === "ArrowDown" ||
        (event.ctrlKey && event.key === "n")
      ) {
        event.preventDefault();
        query.setActiveIndex((index) => {
          let nextIndex =
            index < itemsRef.current.length - 1 ? index + 1 : index;
          // avoid setting active index on a group
          if (typeof itemsRef.current[nextIndex] === "string") {
            if (nextIndex === itemsRef.current.length - 1) return index;
            nextIndex += 1;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        // storing the active dom element in a ref prevents us from
        // having to calculate the current action to perform based
        // on the `activeIndex`, which we would have needed to add
        // as part of the dependencies array.
        activeRef.current?.click();
      }
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [query]);

  useEffect(() => {
    query.setActiveIndex(START_INDEX);
  }, [search, items, query]);

  useEffect(() => {
    if (activeRef.current) {
      scrollIntoView(activeRef.current, {
        scrollMode: "if-needed",
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeRef.current]);

  const execute = useCallback(
    (item: RenderParams["item"]) => {
      query.toggle();
      query.setSearch("");
      options.callbacks?.onSelectAction?.(item);
    },
    [query, options]
  );

  return (
    <Box overflow="auto" p={3} maxHeight="60vh" ref={containerRef}>
      {items.map((item, index) => {
        const active = index === activeIndex;

        return (
          <Box key={item.objectID} role="option" aria-selected={active}>
            {cloneElement(
              onRender({
                item,
                active,
                onMouseMove: () => {
                  query.setActiveIndex(index);
                },
                onClick: () => execute(item),
              }),
              {
                ref: active ? activeRef : null,
              }
            )}
          </Box>
        );
      })}
    </Box>
  );
};
