import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { VFC, forwardRef, Ref, MouseEventHandler } from "react";
import { connectStateResults } from "react-instantsearch-dom";

import { Results } from "../results";

import { Highlight } from "./highlight";

import { Link } from "ui/link";

export type Hit = {
  objectID: string;
  title: string;
  value: string;
  path: string;
  rank: number;
  type: string;
  heirarchy: string[];
  description: string;
};

export const Hits = connectStateResults(({ searchResults }) => {
  if (searchResults?.query?.length < 1 || !searchResults?.hits) {
    return null;
  }

  if (searchResults?.hits?.length === 0) {
    return (
      <Text
        textAlign="center"
        py={8}
        color="text.secondary"
        fontSize="md"
        fontWeight="medium"
      >
        No results match your search
      </Text>
    );
  }

  // sort to keep subsections grouped together
  const sortedResults = sortBy(searchResults.hits, ["rank", "heirarchy"]);

  return (
    <Results
      items={sortedResults}
      onRender={({ item, ...props }) => <Hit hit={item} {...props} />}
    />
  );
});

export const Hit: VFC<
  Readonly<{
    hit: any;
    active: boolean;
    onClick: MouseEventHandler;
    onMouseMove: MouseEventHandler;
  }>
> = forwardRef(
  ({ hit, active, onClick, onMouseMove }, ref: Ref<HTMLAnchorElement>) => {
    const { heirarchy, path, title, type } = hit;

    return (
      <Link
        ref={ref}
        href={path}
        boxSize="100%"
        borderRadius="base"
        display="flex"
        alignItems="start"
        color="text.primary"
        bg={active ? "base.background" : "white"}
        lineHeight="1.625rem"
        fontWeight="semibold"
        fontSize="md"
        p={4}
        _hover={{
          color: "inherit",
        }}
        onClick={onClick}
        onMouseMove={onMouseMove}
      >
        <Box display="inline-block" lineHeight="1.625rem">
          {heirarchy.map((h, index) => {
            return (
              <Box key={`${h}-${index}`} as="span" display="inline-block">
                <Text as="span">{h}</Text>
                <Text as="span" fontSize="lg">
                  &nbsp;
                  {"›"}
                  &nbsp;
                </Text>
              </Box>
            );
          })}
          {type !== "description" ? (
            <Highlight hit={hit} attribute="value" />
          ) : (
            title
          )}
        </Box>
      </Link>
    );
  }
);

Hit.displayName = "Hit";
