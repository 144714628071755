import { Icon, IconProps } from "@chakra-ui/react";

export function LinkIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M11.293 5.293a5.243 5.243 0 0 1 7.414 7.414l-1.25 1.25a1 1 0 0 1-1.414-1.414l1.25-1.25a3.243 3.243 0 1 0-4.586-4.586l-1.25 1.25a1 1 0 1 1-1.414-1.414l1.25-1.25Zm-3.336 4.75a1 1 0 0 1 0 1.414l-1.25 1.25a3.243 3.243 0 0 0 4.586 4.586l1.25-1.25a1 1 0 0 1 1.414 1.414l-1.25 1.25a5.243 5.243 0 1 1-7.414-7.414l1.25-1.25a1 1 0 0 1 1.414 0Z" />
      <path d="M14.957 10.457a1 1 0 0 0-1.414-1.414l-4.5 4.5a1 1 0 1 0 1.414 1.414l4.5-4.5Z" />
    </Icon>
  );
}
