import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { ReactNode, FC, VFC } from "react";

import { CTALinks } from "components/cta-links";
import { Link } from "ui";
import { StarIcon, ChatIcon, AddBoxIcon, QuestionIcon } from "ui/icons";
import { PylonButton } from "ui/pylon";

export const Footer: VFC = () => (
  <Box>
    <SimpleGrid
      alignItems="stretch"
      columns={{ base: 1, lg: 3 }}
      gap={{ base: 14, lg: 10 }}
      mt="180px"
    >
      <GetStarted />
      <Help />
      <Request />
    </SimpleGrid>
    <Flex
      color="text.secondary"
      fontSize="sm"
      justifyContent="right"
      gap={{ base: 10, lg: 6 }}
      align="center"
      justify="center"
      pt={16}
      width="100%"
    >
      <Link variant="subtle" href="https://hightouch.com/privacy-policy">
        Privacy Policy
      </Link>
      <Link variant="subtle" href="https://hightouch.com/terms-of-service">
        Terms of Service
      </Link>
    </Flex>
  </Box>
);

const GetStarted: VFC = () => {
  return (
    <FooterColumn>
      <Box>
        <FooterHeading
          icon={<StarIcon boxSize={5} />}
          title="Ready to get started?"
        />
        <Text color="text.secondary">
          Jump right in or a book a demo. Your first destination is always free.
        </Text>
      </Box>
      <Box mt="auto">
        <CTALinks primary="signup" flex={1} flexWrap="wrap" />
      </Box>
    </FooterColumn>
  );
};

const Help: VFC = () => {
  return (
    <FooterColumn>
      <Box>
        <FooterHeading
          icon={<QuestionIcon boxSize={5} fill="text.primary" />}
          title="Need help?"
        />
        <Text color="text.secondary">
          Our team is relentlessly focused on your success. Don't hesitate to
          reach out!
        </Text>
      </Box>
      <PylonButton
        variant="secondary"
        alignSelf="flex-start"
        justifySelf="flex-end"
        leftIcon={<ChatIcon boxSize={5} color="text.primary" />}
        message="Hello, I have a question!"
      >
        Start a chat
      </PylonButton>
    </FooterColumn>
  );
};

const Request: VFC = () => {
  return (
    <FooterColumn>
      <Box>
        <FooterHeading
          icon={<AddBoxIcon boxSize={5} fill="text.primary" />}
          title="Feature requests?"
        />
        <Text color="text.secondary">
          We'd love to hear your suggestions for integrations and other
          features.
        </Text>
      </Box>
      <PylonButton
        alignSelf="flex-start"
        variant="secondary"
        message="Hello, I have a feature request!"
      >
        Request a feature
      </PylonButton>
    </FooterColumn>
  );
};

interface FooterHeadingProps {
  icon: ReactNode;
  title: string;
}

const FooterHeading: FC<Readonly<FooterHeadingProps>> = ({ icon, title }) => (
  <Flex
    align="center"
    gap={2}
    pb={4}
    mb={4}
    borderBottom="1px solid"
    borderColor="base.border"
  >
    {icon}
    <Text as="h2" fontWeight="semibold" fontSize="lg" whiteSpace="nowrap">
      {title}
    </Text>
  </Flex>
);

const FooterColumn = ({ children }: { children: ReactNode }) => (
  <Flex
    direction="column"
    flex={1}
    justify="space-between"
    alignSelf="stretch"
    gap={4}
    height="100%"
    fontSize="md"
  >
    {children}
  </Flex>
);
