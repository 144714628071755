import { Icon, IconProps } from "@chakra-ui/react";

export const ChatIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M8 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm3 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm3 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12 7c-3.464 0-6 2.378-6 5 0 .91.292 1.77.818 2.517.228.324.248.678.245.873a3.124 3.124 0 0 1-.085.623c-.075.327-.197.701-.301 1.022a46.742 46.742 0 0 0-.16.503c.242-.06.523-.145.841-.246l.227-.073c.375-.12.786-.253 1.158-.347.402-.1.986-.221 1.504-.09A7.092 7.092 0 0 0 12 17c3.464 0 6-2.378 6-5s-2.536-5-6-5ZM5.938 17.623ZM4 12c0-4.005 3.732-7 8-7s8 2.995 8 7-3.732 7-8 7a9.088 9.088 0 0 1-2.247-.28c.006.002.003.002-.01.003a2.551 2.551 0 0 0-.513.09c-.302.075-.636.182-1.009.302l-.255.082c-.448.143-.96.3-1.42.377a3.135 3.135 0 0 1-.757.043 1.685 1.685 0 0 1-.892-.32 1.334 1.334 0 0 1-.506-.913 2.35 2.35 0 0 1 .027-.681c.065-.382.204-.815.319-1.17l.035-.108c.111-.342.203-.624.256-.857.009-.038.015-.07.02-.098A6.323 6.323 0 0 1 4 12Zm1.063 3.357a.15.15 0 0 1 0 .004v-.004Z"
      clipRule="evenodd"
    />
  </Icon>
);
