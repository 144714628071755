import { Text, Link } from "@chakra-ui/react";

export const LiveDebugger = () => (
  <Text textStyle="paragraph">
    Hightouch provides complete visibility into the API calls made during each
    of your sync runs. We recommend reading our{" "}
    <Link variant="inline" href={"/docs/syncs/debugger"}>
      article on debugging tips and tricks
    </Link>{" "}
    to learn more.
  </Text>
);
