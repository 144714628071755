import { Tip } from "../alert";
import { Link } from "../link";

export const MatchBoosterDestination = ({
  destinationName = "This destination",
}) => (
  <Tip>
    Increase match rates in {destinationName} by enabling{" "}
    <Link variant="inline" href={"/match-booster/overview"}>
      Match Booster
    </Link>
    .
  </Tip>
);
