import { Box, Text } from "@chakra-ui/react";

import { Tip } from "ui/alert";
import { Image } from "ui/image";
import { Link } from "ui/link";
import { Strong } from "ui/strong";

export const SyncEngineSnippet = ({ source = "your data warehouse" }) => (
  <>
    <Box>
      <Text textStyle="paragraph">
        For optimal performance, Hightouch{" "}
        <Link
          variant="inline"
          href="/docs/getting-started/concepts#change-data-capture"
        >
          tracks incremental changes
        </Link>{" "}
        in your data model—such as added, changed, or removed rows—and only
        syncs those records. You can choose between two different sync engines
        for this work.
      </Text>
      <Text>
        The <Strong> Basic engine </Strong> requires read-only access to{" "}
        {source}. Hightouch executes a query in your database, reads all query
        results, and then determines incremental changes using Hightouch's
        infrastructure. This engine is easier to set up since it requires
        read—not write—access to {source}.
      </Text>
      <Text textStyle="paragraph">
        The <Strong>Lightning engine </Strong> requires read and write access to{" "}
        {source}. The engine stores previously synced data in a separate schema
        in {source} managed by Hightouch. In other words, the engine uses{" "}
        {source} to track incremental changes to your data rather than
        performing these calculations in Hightouch. Therefore, these
        computations are completed more quickly.
      </Text>
      <Image
        alt="Standard vs Lightning engine comparison"
        src="/get-started/sync-engine-comparison.png"
        title=""
        objectFit="contain"
        width="auto"
        height="auto"
        my={6}
      />
      <Tip>
        Hightouch recommends using the Lightning sync engine when syncing more
        than 100 thousand rows of data.
      </Tip>
      <Text textStyle="paragraph">
        If you select the Basic engine, you can switch to the Lightning engine
        later. Once you've configured the Lightning engine, you can't move back
        to the Basic engine without recreating {source} as a source.
      </Text>
      <Text textStyle="paragraph">
        To learn more, including migration steps and tips, check out the{" "}
        <Link variant="inline" href="/docs/syncs/lightning-sync-engine">
          Lightning sync engine docs
        </Link>
        .
      </Text>
    </Box>
  </>
);
