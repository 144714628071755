import { Box, Button, Flex, Input } from "@chakra-ui/react";
import { connectSearchBox } from "react-instantsearch-dom";

import { CloseIcon, SearchIcon } from "ui/icons";

export const SearchInput = connectSearchBox(({ refine, currentRefinement }) => {
  return (
    <Flex
      width="100%"
      px={4}
      height="60px"
      align="center"
      borderBottom="1px"
      borderColor="base.divider"
    >
      <SearchIcon fill="text.secondary" boxSize={6} />
      <Input
        autoFocus
        value={currentRefinement}
        spellCheck={false}
        border="none"
        outline="none"
        fontWeight="normal"
        fontSize="xl"
        flex={1}
        placeholder="Search docs..."
        sx={{
          caretColor: "var(--theme-colors-text-secondary)",
          "::placeholder": {
            color: "var(--theme-colors-text-tertiary)",
          },
        }}
        onChange={(e) => {
          refine(e.currentTarget.value);
        }}
      />
      <Box
        as={Button}
        variant="subtle"
        visibility={currentRefinement ? "visible" : "hidden"}
        pointerEvents={currentRefinement ? "all" : "none"}
        onClick={() => {
          refine("");
        }}
      >
        <CloseIcon
          cursor="pointer"
          ml="auto"
          boxSize={5}
          color="text.tertiary"
        />
      </Box>
    </Flex>
  );
});
