import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Link: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "base",
    transition: "color 150ms ease-in-out",
    _focus: {
      outline: "none !important",
    },
    _focusVisible: {
      outline: "none !important",
      boxShadow: "outline",
    },
    _disabled: {
      pointerEvents: "none",
    },
  },
  variants: {
    primary: {
      color: "link.default",
      fontWeight: "semibold",
      _hover: {
        color: "link.hover",
      },
      _active: {
        color: "link.pressed",
      },
    },
    secondary: {
      color: "inherit",
      _hover: {
        color: "link.default",
      },
    },
    inline: {
      color: "link.default",
      // fontWeight: "semibold", XX: should we do this?
      fontSize: "inherit",
      lineHeight: "inherit",
      textDecoration: "underline",
      textUnderlineOffset: "4px",
      _hover: {
        color: "link.hover",
      },
      _active: {
        color: "link.pressed",
      },
    },
    subtle: {
      color: "text.tertiary",
      fontWeight: "medium",
      _hover: {
        color: "text.primary",
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
};
