import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Kbd: ComponentStyleConfig = {
  baseStyle: {
    display: "flex",
    flexShrink: 0,
    height: "20px",
    width: "20px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "sm",
    fontWeight: "medium",
    fontSize: "md",
    lineHeight: "normal",
    bg: "gray.200",
    color: "text.tertiary",
    whiteSpace: "nowrap",
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
