import { Box, Text } from "@chakra-ui/react";

import { Info } from "ui/alert";
import { Link } from "ui/link";

export const SyncLogsSnippet = ({ source = "your data warehouse" }) => (
  <>
    <Box>
      <Text textStyle="paragraph">
        You may also want to consider{" "}
        <Link variant="inline" href={"/syncs/warehouse-sync-logs"}>
          storing sync logs
        </Link>{" "}
        in {source}. Like using the Lightning sync engine versus the standard
        one, this feature lets you use {source} instead of Hightouch
        infrastructure. Rather than performance gains, it makes your sync log
        data available for more complex analysis. Refer to the{" "}
        <Link variant="inline" href="/syncs/warehouse-sync-logs">
          warehouse sync logs docs
        </Link>{" "}
        to learn more.
      </Text>
      <Info>
        <p>
          You must enable the{" "}
          <Link variant="inline" href="#choose-your-sync-engine">
            Lightning sync engine
          </Link>{" "}
          to store sync logs in your warehouse.
        </p>
      </Info>
    </Box>
  </>
);
