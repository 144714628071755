import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      border: "1px solid",
      borderColor: "gray.300",
      fontWeight: "medium",
      borderRadius: "base",
      transition: "150ms color border-color background-color",
      _placeholder: {
        opacity: 1,
        color: "gray.300",
      },
    },
  },
  sizes: {
    md: {
      field: {
        height: "32px",
        px: 2,
        fontSize: "sm",
      },
    },
  },
  variants: {},
  defaultProps: {
    size: "md",
  },
};
