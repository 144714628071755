import { Warning } from "../alert";
import { Link } from "../link";

export const EventSyncWarning = ({
  featureName = "This feature",
  plural = false,
  extraText = "",
  type = "event",
}) => (
  <Warning>
    To ensure syncs send each {type}, your event model must use a truly unique{" "}
    <Link
      variant="inline"
      href={"/getting-started/concepts#unique-primary-key-requirement"}
    >
      primary key
    </Link>
    . See the{" "}
    <Link variant="inline" href={"/syncs/types-and-modes#event-syncs"}>
      events syncs
    </Link>{" "}
    documentation for more information.
  </Warning>
);
