import { Box, ChakraProps, Flex, Text } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { kebabCase } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useMemo, useRef, useState, VFC } from "react";

import { config, NavItem } from "./config";

import { User } from "api/get-user";
import { CTALinks } from "components/cta-links";
import { Animation, Link } from "ui";
import { ChevronRightIcon } from "ui/icons";

const subNavLeftPadding = 6;

const navItemProps: ChakraProps = {
  cursor: "pointer",
  userSelect: "none",
  border: "1px",
  borderColor: "white",
  borderRadius: "base",
  fontSize: "md",
  fontWeight: "normal",
  py: 1,
  _focus: {
    outline: "none !important",
  },
  _focusVisible: {
    outline: "none",
    boxShadow: "outline",
  },
};

type Props = {
  destinations?: Array<any> | undefined;
  sources?: Array<any> | undefined;
  toggle?: () => void;
  user?: User;
};

export const Nav: VFC<Readonly<Props>> = (props) => {
  const router = useRouter();
  const [initial, setInitial] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string>(
    `/${router.pathname.split("/")[1]}`
  );

  const processNav = (nav: NavItem[], depth: number, pathPrefix = "") => {
    return nav.map((item) => processNavItem(item, depth, pathPrefix));
  };

  const processNavItem = (item: NavItem, depth: number, pathPrefix = "") => {
    const path = `${pathPrefix}${item.path ?? `/${kebabCase(item.label)}`}`;

    if (item.private && !props.user?.private) {
      return null;
    }

    if (depth === 1) {
      const isActive = router.pathname.startsWith(path);
      const isExpanded = expanded === path;

      return (
        <Fragment key={item.label}>
          <Flex
            {...navItemProps}
            height="36px"
            align="center"
            px={2}
            whiteSpace="nowrap"
            transition="150ms background-color"
            _hover={{ bg: "base.background" }}
            onClick={() => {
              if (isExpanded) {
                setExpanded("");
              } else if (expanded) {
                setExpanded("");
                setTimeout(() => {
                  setExpanded(path);
                }, 200);
              } else {
                setExpanded(path);
              }
            }}
          >
            <Flex align="center" justify="space-between" flex={1}>
              <Text fontWeight="medium">{item.label}</Text>
              <ChevronRightIcon
                boxSize={4}
                transition="transform 0.15s ease-in-out"
                transform={isExpanded ? "rotate(90deg)" : "rotate(0deg)"}
              />
            </Flex>
          </Flex>
          <AnimatePresence>
            {isExpanded && (
              <Animation
                initial="collapsed"
                animate="expanded"
                exit="collapsed"
                variants={{
                  expanded: {
                    height: "auto",
                    transition: {
                      duration: 0.15,
                      type: "tween",
                    },
                  },
                  collapsed: {
                    height: 0,
                    transition: { type: "tween", duration: 0.05 },
                  },
                }}
              >
                {item.nav && processNav(item.nav, depth + 1, path)}
              </Animation>
            )}
          </AnimatePresence>
        </Fragment>
      );
    }
    const isGroupLabel = depth === 2 && item.nav?.length;

    if (isGroupLabel) {
      return (
        <Animation
          variants={{
            expanded: { opacity: 1, transition: { duration: 0.25 } },
            collapsed: { opacity: 0, transition: { duration: 0.05 } },
          }}
        >
          <Text
            textStyle="uppercase"
            ml={subNavLeftPadding}
            mt={2}
            pt={4}
            pb={2}
            borderTop="1px"
            borderColor="base.divider"
          >
            {item.label}
          </Text>
          {item.nav && processNav(item.nav, depth + 1, pathPrefix)}
        </Animation>
      );
    }

    const isActive =
      router.asPath === path ||
      (router.pathname === "/getting-started/welcome" &&
        path === "/getting-started/welcome");

    return (
      <Animation
        variants={{
          expanded: { opacity: 1, transition: { duration: 0.25 } },
          collapsed: { opacity: 0, transition: { duration: 0.05 } },
        }}
      >
        <Link
          {...navItemProps}
          key={item.label}
          href={item.href ?? path}
          display="flex"
          minHeight="32px"
          alignItems="center"
          pl={subNavLeftPadding}
          color={isActive ? "text.primary" : "text.secondary"}
          bg={isActive ? "base.background" : "transparent"}
          transition="150ms all ease-in-out"
          _hover={{ bg: "base.background" }}
          onClick={props.toggle}
        >
          <Text color="inherit">{item.label}</Text>
        </Link>
      </Animation>
    );
  };

  const navConfig = useMemo(
    () =>
      config.map((item) => {
        if (item.label === "Sources") {
          return {
            ...item,
            nav: props.sources
              ?.filter(
                ({ status }) => status === "released" || status === "alpha"
              )
              .map(({ name, slug }) => ({
                label: name,
                path: `/${slug.replace("-source", "")}`,
              })),
          };
        }
        if (item.label === "Destinations") {
          return {
            ...item,
            nav: props.destinations
              ?.filter(
                ({ slug, status }) =>
                  slug !== "hightouch-personalization-api" &&
                  (status === "released" || status === "alpha")
              )
              .map(({ name, slug }) => {
                let slugOverride = undefined;
                let nameOverride = undefined;
                if (slug === "webhook-v2") {
                  slugOverride = "http-request";
                }
                if (slug === "hightouch-personalization-api") {
                  slugOverride = "personalization-api";
                }
                if (name === "Google Sheets - User Account") {
                  nameOverride = "Google Sheets";
                }
                return {
                  label: nameOverride ? nameOverride : name,
                  path: `/${
                    slugOverride
                      ? slugOverride
                      : slug.replace("-destination", "")
                  }`,
                };
              }),
          };
        }
        return item;
      }),
    [props.destinations, props.sources]
  );

  const content = useMemo(
    () => processNav(navConfig, 1),
    [navConfig, expanded, router.pathname, processNav, props.user?.private]
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (expanded && containerRef.current) {
      if (initial) {
        setInitial(false);
      } else {
        const container = document.querySelector("#nav");
        if (container) {
          const el = container.querySelector(
            `a[href="/docs${router.pathname}/"]`
          );
          if (el) {
            container.scrollTo({
              top:
                (el as HTMLElement).offsetTop -
                (container as HTMLElement).offsetTop,
              behavior: "smooth",
            });
          }
        }
      }
    }
  }, [expanded, router.pathname, containerRef]);

  useEffect(() => {
    const expectedExpanded = `/${router.pathname.split("/")[1]}`;
    if (expanded !== expectedExpanded) {
      setExpanded(expectedExpanded);
    }
  }, [router.pathname]);

  return (
    <Flex
      direction="column"
      width="100%"
      p={{ base: 2, md: 4 }}
      ref={containerRef}
    >
      {content}
      <CTALinks
        includeChangelog
        hideSecondary
        primary="signup"
        display={{ base: "flex", md: "none" }}
        mt={8}
        mx={6}
      />
    </Flex>
  );
};
