import { Button, Flex, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { VFC, useState } from "react";

import { track } from "lib/analytics";
import { SmileIcon, FrownIcon } from "ui/icons";
import { PylonButton } from "ui/pylon";

export const Feedback: VFC = () => {
  const router = useRouter();
  const [isHappy, setIsHappy] = useState<boolean>(false);

  return (
    <Flex
      direction="column"
      fontSize="md"
      color="text.secondary"
      pt={4}
      mt={2}
      borderTop="1px"
      borderColor="base.divider"
    >
      <Text mb={2}>Was this page helpful?</Text>
      <Flex align="center" gap={4}>
        {isHappy ? (
          <Flex align="center" height="32px" px={2}>
            <SmileIcon />
            <Text ml={2}>Thank you!</Text>
          </Flex>
        ) : (
          <>
            <Button
              variant="subtle"
              onClick={() => {
                track("Page Helpful Icon Clicked", {
                  page: router.pathname,
                });
                setIsHappy(true);
              }}
            >
              <SmileIcon />
              <Text as="span" ml={2}>
                Yes
              </Text>
            </Button>
            <PylonButton
              variant="subtle"
              message="Hello, the docs didn't answer my question. I need help with..."
            >
              <FrownIcon />
              <Text as="span" ml={2}>
                No
              </Text>
            </PylonButton>
          </>
        )}
      </Flex>
    </Flex>
  );
};
