import { Text, Box } from "@chakra-ui/react";

import { Image } from "ui/image";
import { Link } from "ui/link";

export const TimestampTemplateMapping = ({ alternateImageSrc }) => (
  <>
    <Text textStyle="paragraph">
      A convenient way to convert your date values to Unix millisecond
      timestamps is to use the <code>to_unix</code> function in the{" "}
      <Link variant="inline" href="/docs/syncs/mapping-data#template-mapping">
        template mapper
      </Link>
      .
    </Text>
    <Image
      alt="Field mapping in the Hightouch UI"
      src={
        alternateImageSrc
          ? `/${alternateImageSrc}`
          : "/mapping-data/mapping-data-template-map-to-unix.png"
      }
      title=""
      objectFit="contain"
      width="auto"
      height="auto"
      my={6}
    />
    <Text textStyle="paragraph">
      The <code>to_unix</code> function throws an error if the input row's value
      is <code>null</code>. To avoid this, you can enter this{" "}
      <Link variant="inline" href="https://shopify.dev/docs/api/liquid">
        Liquid
      </Link>{" "}
      into the template mapper:
    </Text>
    <Box
      as="code"
      bg="base.lightBackground"
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      color="text.primary"
      display="block"
      fontFamily="mono"
      fontSize="sm"
      padding="1em"
      overflowX="auto"
      whiteSpace="pre-wrap"
    >
      <span>{`{%- if row['column_name'] == null -%}\n`}</span>
      <span>{`{{null}}\n`}</span>
      <span>{`{%- else -%}\n`}</span>
      <span>{`{{row['column_name'] | to_unix}}\n`}</span>
      <span>{`{%- endif -%}`}</span>
    </Box>
    <Text textStyle="paragraph">
      Be sure to replace <code>column_name</code> with the name of your model
      column.
    </Text>
  </>
);
