import { Global as EmotionGlobal } from "@emotion/react";

const Fonts = () => (
  <EmotionGlobal
    styles={`
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-display: block;
        src: url(/docs/fonts/Inter-Regular.woff2) format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: italic;
        font-weight: 400;
        font-display: block;
        src: url(/docs/fonts/Inter-Italic.woff2) format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-display: block;
        src: url(/docs/fonts/Inter-Medium.woff2) format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: italic;
        font-weight: 500;
        font-display: block;
        src: url(/docs/fonts/Inter-MediumItalic.woff2) format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-display: block;
        src: url(/docs/fonts/Inter-SemiBold.woff2) format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: italic;
        font-weight: 600;
        font-display: block;
        src: url(/docs/fonts/Inter-SemiBoldItalic.woff2) format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-display: block;
        src: url(/docs/fonts/Inter-Bold.woff2) format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: italic;
        font-weight: 700;
        font-display: block;
        src: url(/docs/fonts/Inter-BoldItalic.woff2) format("woff2");
      }
      @font-face {
        font-family: "Nuckle";
        font-style: normal;
        font-weight: 400;
        font-display: block;
        src: url(/docs/fonts/Nuckle-Regular.woff2) format("woff2");
      }
      @font-face {
        font-family: "Nuckle";
        font-style: normal;
        font-weight: 600;
        font-display: block;
        src: url(/docs/fonts/Nuckle-SemiBold.woff2) format("woff2");
      }
      @font-face {
        font-family: "Nuckle";
        font-style: normal;
        font-weight: 700;
        font-display: block;
        src: url(/docs/fonts/Nuckle-Bold.woff2) format("woff2");
      }
    `}
  />
);

const Code = () => (
  <EmotionGlobal
    styles={`
    .hljs-comment {
      color: var(--theme-colors-text-secondary)
    }
    .hljs-addition {
      color: var(--theme-colors-success-700);
      background: var(--theme-colors-success-100);
    }
    .hljs-deletion {
      color: var(--theme-colors-danger-700);
      background: var(--theme-colors-danger-100);
    }
  `}
  />
);

export const Global = () => (
  <>
    <Code />
    <Fonts />
  </>
);
