import { VStack, Text, Code, UnorderedList, ListItem } from "@chakra-ui/react";
import { Strong } from "ui/strong";

export const MatchRatesExplanation = ({ destination }) => (
  <VStack textStyle="paragraph" spacing={4}>
    <Text>
      Hightouch retrieves the audience metadata from {destination} in real-time.
      The matched number displayed in Hightouch should reflect what you see in{" "}
      {destination}. Note that the matched numbers are approximated to maintain
      privacy thresholds. Hightouch takes the matched count to calculate a match
      rate for your sync, where applicable. The calculation breakdown:
    </Text>
    <Code>matched_user_count / # of rows queried in the latest sync run</Code>
    <Text>
      The match rate is <Strong>not</Strong> calculated when:
      <UnorderedList>
        <ListItem>
          syncing to an existing segment because the total number of records to
          ever be uploaded to the segment is unknown
        </ListItem>
        <ListItem>
          removed users from your model is not removed in {destination} because
          the matched user count would be inflated in the calculation
        </ListItem>
      </UnorderedList>
    </Text>
    <Text>
      Common causes for low match rates:
      <UnorderedList>
        <ListItem>
          Your audience model is too small. Most ad platforms do not display the
          matched number unless there's at least one thousand matched users to
          maintain privacy thresholds.
        </ListItem>
        <ListItem>
          The upload is still processing. We recommend waiting at least{" "}
          <Strong>72 hours</Strong> from the first sync run for numbers to
          settle.
        </ListItem>
        <ListItem>
          Your data isn’t cleaned or hashed properly. Hightouch normalizes and
          hashes your data according to destination requirements, but it’s still
          good to make sure that the data is as clean as possible. Note that
          Hightouch cannot clean your data if you opt to hash it yourself. In
          that case, ensure you follow the data cleaning requirements for
          {destination}.
        </ListItem>
      </UnorderedList>
    </Text>
  </VStack>
);
