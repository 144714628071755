import { Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export function Strong({ children }: { children: ReactNode }) {
  return (
    <Text as="span" fontWeight="semibold">
      {children}
    </Text>
  );
}
