import { Heading as ThemedHeading, useClipboard } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FC } from "react";

import { LinkIcon } from "ui/icons";
import { Link } from "ui/link";

type Props = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
  id?: string;
};

export const Heading: FC<Readonly<Props>> = ({ children, as = "h2", id }) => {
  const router = useRouter();
  const { onCopy } = useClipboard(
    typeof window === "undefined"
      ? null
      : `${window.location.href}${id ? `#${id}` : ""}`
  );

  return (
    <ThemedHeading
      as={as}
      textStyle={as}
      id={id}
      className="group"
      onClick={onCopy}
    >
      <Link
        variant="secondary"
        href={id ? `#${id}` : router.asPath}
        display="flex"
        alignItems="center"
      >
        {children}
        <LinkIcon
          boxSize={5}
          ml={3}
          color="primary.base"
          opacity={0}
          transition="150ms opacity"
          _groupHover={{ opacity: 1 }}
        />
      </Link>
    </ThemedHeading>
  );
};
