import { Text, Flex } from "@chakra-ui/react";
import { VFC } from "react";

import { Link } from "ui";
import { BreakdownIcon } from "ui/icons";

export type TableOfContentsItem = {
  value: string;
  depth: number;
  id: string;
};

type Props = {
  toc: TableOfContentsItem[];
};

export const TableOfContents: VFC<Readonly<Props>> = ({ toc }) => {
  if (!toc?.length) {
    return null;
  }

  return (
    <Flex
      direction="column"
      color="text.secondary"
      overflow="hidden"
      borderTop="1px"
      borderColor="base.divider"
    >
      <Flex align="center" gap={1} mt={3} mb={1}>
        <BreakdownIcon boxSize={5} />
        <Text fontSize="md" as="span">
          On this page
        </Text>
      </Flex>
      <Flex direction="column" overflowY="auto" maxHeight="80vh" p={1}>
        <Flex as="ul" direction="column">
          {toc.map(({ value, id, depth }) => (
            <Flex as="li" key={id} w="100%">
              <Link
                href={`#${id}`}
                variant="subtle"
                py={1}
                pl={depth * 4}
                fontSize="md"
                w="100%"
              >
                {value}
              </Link>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
