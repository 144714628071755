import { Text } from "@chakra-ui/react";

import { Link } from "../link";

import { OrderedList, ListItem } from "ui/list";
import { Strong } from "ui/strong";

export const CreateAPIKeySnippet = () => (
  <OrderedList>
    <ListItem>
      From the{" "}
      <Link href="https://app.hightouch.com/settings/api-keys" variant="inline">
        <Strong>API keys</Strong> tab
      </Link>{" "}
      on the <Strong>Settings</Strong> page, select <Strong>Add API key</Strong>
      .
    </ListItem>
    <ListItem>
      Enter a descriptive <Strong>Name</Strong> for your key.
    </ListItem>
    <ListItem>
      Copy your <Strong>API key</Strong> and store it in a safe location. The
      key will only be displayed once.
    </ListItem>
    <ListItem>
      Click <Strong>Create API key</Strong>.
    </ListItem>
  </OrderedList>
);
