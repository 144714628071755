import { Box, Text } from "@chakra-ui/react";

import { Link } from "../link";

import { Image } from "ui/image";
import { Strong } from "ui/strong";

export const SetSyncSchedule = ({ extension = "" }) => (
  <>
    <Box>
      <Text textStyle="paragraph">
        For the {extension} extension to trigger syncs via the Hightouch API,
        you must set the syncs'{" "}
        <Link href="/syncs/schedule-sync-ui" variant="inline">
          schedules
        </Link>{" "}
        to <Strong>Manual</Strong>.
      </Text>
      <Text textStyle="paragraph">
        You can do this by going to the{" "}
        <Link href="https://app.hightouch.com/syncs" variant="inline">
          <Strong>Syncs </Strong>
          overview page
        </Link>
        , selecting the particular sync you want to trigger with {extension},
        and opening the <Strong>Schedule</Strong> tab. Here, make sure the{" "}
        <Strong>Schedule type</Strong> is set to <Strong>Manual</Strong>. You
        should do this for each sync you want to trigger with {extension}.
      </Text>
      <Image
        alt="Manual sync Hightouch UI"
        src="/syncs/manual-schedule.png"
        title=""
        objectFit="contain"
        width="auto"
        height="auto"
        my={6}
      />
      <Text textStyle="paragraph">
        You can also find the sync ID on this page, which you need for
        scheduling syncs in the next step.
      </Text>
      <Image
        alt="A sync ID in the Hightouch UI"
        src="/syncs/sync-id.png"
        title=""
        objectFit="contain"
        width="auto"
        height="auto"
        my={6}
      />
    </Box>
  </>
);
