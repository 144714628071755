import {
  TableContainer,
  Table as ChakraTable,
  Td,
  Tr,
  Th,
} from "@chakra-ui/react";
import { FC } from "react";

export const Table: FC = ({ children }) => (
  <TableContainer
    my={12}
    width="100%"
    mx="auto"
    overflow={{ base: "auto", md: undefined }}
    whiteSpace={{ base: "pre", md: "unset" }}
    sx={{
      tableLayout: "auto",
      borderCollapse: "collapse",
    }}
  >
    <ChakraTable>{children}</ChakraTable>
  </TableContainer>
);

export const TD: FC = ({ children }) => (
  <Td
    px={4}
    py={2}
    textAlign="left"
    borderBottom="1px solid"
    borderColor="base.border"
  >
    {children}
  </Td>
);

export const TR: FC = ({ children }) => <Tr>{children}</Tr>;

export const TH: FC = ({ children }) => (
  <Th
    as="th"
    px={4}
    py={2}
    textAlign="left"
    fontWeight="semibold"
    fontSize="sm"
    textTransform="uppercase"
    bg="base.background"
    color="text.primary"
  >
    {children}
  </Th>
);
