import { Button, Flex, Kbd, Text } from "@chakra-ui/react";
import { useKBar } from "kbar";
import { VFC } from "react";

import { SearchIcon } from "ui/icons";

export const SearchBox: VFC = () => {
  const { query } = useKBar();

  return (
    <Flex
      as={Button}
      aria-label="Search docs"
      px={2}
      height="36px"
      bg="base.lightBackground"
      border="1px"
      borderColor="base.border"
      color="text.tertiary"
      justify="space-between"
      fontSize="md"
      align="center"
      width="100%"
      maxWidth="260px"
      _hover={{
        background: "base.background",
        color: "text.secondary",
      }}
      onClick={() => {
        query.toggle();
      }}
    >
      <Flex align="center" gap={2}>
        <SearchIcon boxSize={5} fill="text.tertiary" />
        <Text fontWeight="normal">Search docs...</Text>
      </Flex>

      <Flex align="center" gap={1} display={["none", "none", "flex"]}>
        <Kbd fontSize="xl">⌘</Kbd>
        <Kbd>K</Kbd>
      </Flex>
    </Flex>
  );
};
