import { Box, Text } from "@chakra-ui/react";

import { Image } from "ui/image";
import { Link } from "ui/link";

export const ConnectionTypeSnippet = ({ source = "data warehouse" }) => (
  <>
    <Box>
      <Text textStyle="paragraph">
        Hightouch can connect directly to {source} over the public internet or
        via an SSH tunnel. Since data is encrypted in transit via TLS, a direct
        connection is suitable for most use cases. You may need to set up a
        tunnel if your {source} instance is on a private network or virtual
        private cloud (VPC).
      </Text>
      <Image
        alt="Direct connection vs. SSH tunnel"
        src="/get-started/connection-types-comparison.png"
        title=""
        objectFit="contain"
        width="auto"
        height="auto"
        my={6}
      />
      <Text>
        {" "}
        Hightouch supports both standard and reverse SSH tunnels. To learn more
        about SSH tunneling, refer to Hightouch's{" "}
        <Link variant="inline" href="/docs/security/ssh-tunneling">
          tunneling documentation
        </Link>
        .
      </Text>
    </Box>
  </>
);
