import { TriangleUpIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Button, Flex, Text } from "@chakra-ui/react";
import { VideoJsonLd } from "next-seo";
import { FC, useRef, useState, useCallback } from "react";

import { track } from "lib/analytics";
import { PlayIcon } from "ui/icons";

interface VideoProps extends BoxProps {
  source: string;
  poster?: string;
  title: string;
  description: string;
  uploadDate?: string;
  duration?: string;
  hideTitle?: boolean;
  size?: "sm" | "base";
}

export const Video: FC<Readonly<VideoProps>> = ({
  source,
  poster,
  title,
  description,
  uploadDate,
  duration,
  hideTitle,
  size = "base",
  ...props
}) => {
  const video = useRef<HTMLVideoElement>(null);
  const [isPlayed, setIsPlayed] = useState(false);

  const play = useCallback(() => {
    video.current?.play();
    video.current?.setAttribute("controls", "true");
    setIsPlayed(true);
  }, []);

  const onPlay = () => {
    track("Video Played", {
      name: title,
      page: window.location.href,
    });
  };

  const onEnded = () => {
    track("Video Ended", {
      Name: title,
      page: window.location.href,
    });
  };

  return (
    <>
      <Box
        position="relative"
        borderRadius="xl"
        overflow="hidden"
        mt={8}
        mb={8}
        {...props}
      >
        {!isPlayed && (
          <Button
            aria-label="Play video"
            variant="unstyled"
            position="absolute"
            w="100%"
            h="100%"
            pt={title && duration ? [0, 0, 12] : 0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            zIndex={2}
            role="group"
            transition=".15s all ease"
            _hover={{ transform: "scale(1.1)" }}
            onClick={play}
          >
            <PlayIcon boxSize={size === "base" ? 20 : 12} color="white" />

            {title && !hideTitle && (
              <Flex
                flexDir="column"
                gap={3}
                display={["none", "none", "flex"]}
                bg="white"
                borderRadius="sm"
                opacity={0}
                mt={-2}
                p={4}
                boxShadow="sm"
                zIndex={-1}
                transition=".15s opacity ease"
                _groupHover={{ opacity: 1, h: "auto" }}
              >
                <Text as="span" fontSize="sm">
                  {title}
                </Text>
                {duration && (
                  <Text as="span" fontSize="sm" color="text.secondary" pt={2}>
                    {duration}
                  </Text>
                )}
              </Flex>
            )}
          </Button>
        )}
        <Box
          _before={{
            position: "absolute",
            content: isPlayed ? "none" : "''",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            bg: "rgba(0, 0, 0, 0.4)",
            zIndex: 1,
          }}
        >
          <video
            ref={video}
            width="100%"
            poster={poster}
            preload="metadata"
            onPlay={onPlay}
            onEnded={onEnded}
          >
            <source src={source} type="video/mp4" />
          </video>
        </Box>
      </Box>

      <VideoJsonLd
        name={title}
        description={description}
        contentUrl={source}
        embedUrl={source}
        thumbnailUrls={[poster]}
        uploadDate={uploadDate || "2022-09-14T08:00:00+08:00"}
      />
    </>
  );
};
