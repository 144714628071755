// Product color palette from https://www.figma.com/file/rLsSTygVap7zagoErmncwm/Styles
const colors = {
  forest: {
    "50": "#f8fbfc",
    "100": "#effafb",
    "200": "#d3f1f3",
    "300": "#b3d9db",
    "400": "#75b7bb",
    "500": "#488589",
    "600": "#1d656a",
    "700": "#03484d",
    "800": "#043d41",
    "900": "#002528",
  },
  teal: {
    "50": "#f5fcff",
    "100": "#ebfcff",
    "200": "#cef7fd",
    "300": "#a5e0f9",
    "400": "#6ac1e7",
    "500": "#349ac6",
    "600": "#0e6b93",
    "700": "#0A4F6D",
    "800": "#063448",
    "900": "#021822",
  },
  space: {
    "50": "#f5f8ff",
    "100": "#ecf1fe",
    "200": "#c9d8fe",
    "300": "#adbeeb",
    "400": "#8ea3d9",
    "500": "#6C85C5",
    "600": "#425c9d",
    "700": "#2e447d",
    "800": "#222f51",
    "900": "#0c1428",
  },
  warning: {
    "50": "#fffcf5",
    "100": "#fef9ec",
    "200": "#feeec1",
    "300": "#feda76",
    "400": "#f5c24d",
    "500": "#E49A38",
    "600": "#B35808",
    "700": "#9E4D06",
    "800": "#6D3708",
    "900": "#412106",
  },
  danger: {
    "50": "#fffbfa",
    "100": "#fff3f2",
    "200": "#ffe7e5",
    "300": "#f9c8c4",
    "400": "#f59993",
    "500": "#d25046",
    "600": "#A8271E",
    "700": "#7C130C",
    "800": "#410A06",
    "900": "#411008",
  },
  gray: {
    "50": "#fbfcfd",
    "100": "#F2F4F7",
    "200": "#e8edf2",
    "300": "#dbe1e8",
    "400": "#bbc5d0",
    "500": "#9aa6b2",
    "600": "#687685",
    "700": "#4E5C6B",
    "800": "#364452",
    "900": "#252d36",
  },
  grass: {
    "50": "#f5fffa",
    "100": "#ecfef5",
    "200": "#b8f2d4",
    "300": "#9FE8C3",
    "400": "#79d5a6",
    "500": "#4FC26B",
    "600": "#299944",
    "700": "#006a34",
    "800": "#004723",
    "900": "#002C15",
  },
};

const themeColors = {
  ...colors,
  text: {
    primary: colors.gray["900"],
    secondary: colors.gray["700"],
    tertiary: colors.gray["600"],
    placeholder: colors.gray["600"],
    danger: colors.danger["600"],
    disabled: colors.gray["500"],
  },
  base: {
    border: colors.gray["300"],
    divider: colors.gray["200"],
    background: colors.gray["100"],
    lightBackground: colors.gray["50"],
  },
  primary: {
    pressed: colors.forest["800"],
    hover: colors.forest["700"],
    base: colors.forest["600"],
    border: colors.forest["300"],
    background: colors.forest["100"],
  },
  teal: {
    ...colors.teal,
    base: colors.teal["600"],
    border: colors.teal["400"],
    background: colors.teal["100"],
  },
  grass: {
    ...colors.grass,
    base: colors.grass["600"],
    border: colors.grass["400"],
    background: colors.grass["100"],
  },
  success: {
    base: colors.grass["700"],
    border: colors.grass["400"],
    background: colors.grass["100"],
  },
  warning: {
    ...colors.warning,
    base: colors.warning["700"],
    border: colors.warning["400"],
    background: colors.warning["100"],
  },
  link: {
    pressed: colors.teal["800"],
    hover: colors.teal["700"],
    default: colors.teal["600"],
  },
};

export default themeColors;

export type Colors = typeof themeColors;
