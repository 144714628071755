import { Icon, IconProps } from "@chakra-ui/react";

export function StarIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M11.057 3.658c.323-.877 1.563-.877 1.886 0l1.893 5.139 5.216.305c.903.053 1.282 1.18.595 1.768l-4.086 3.505 1.35 5.37c.225.896-.775 1.597-1.54 1.078L12 17.857l-4.372 2.966c-.764.519-1.764-.182-1.539-1.078l1.35-5.37-4.086-3.505c-.687-.589-.308-1.715.595-1.768l5.216-.305 1.893-5.139ZM12 6.385l-1.308 3.55a1.005 1.005 0 0 1-.884.656l-3.718.218 2.925 2.509c.29.248.414.639.32 1.009l-.947 3.771 3.048-2.068c.34-.23.787-.23 1.128 0l3.048 2.068-.948-3.771a1.01 1.01 0 0 1 .32-1.01l2.926-2.508-3.718-.218a1.005 1.005 0 0 1-.884-.656L12 6.385Z"
        clipRule="evenodd"
      />
    </Icon>
  );
}
