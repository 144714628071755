import { ChakraStyledOptions } from "@chakra-ui/react";

const inter = `"Inter", sans-serif;`;
const menlo = `Menlo, monospace`;
const nuckle = `"Nuckle", sans-serif;`;

export const typography: ChakraStyledOptions = {
  fontSizes: {
    "2xs": "10px",
    xs: "11px",
    sm: "12px",
    md: "13px",
    lg: "15px",
    xl: "18px",
    "2xl": "21px",
    "3xl": "24px",
    "4xl": "32px",
  },
  fonts: {
    body: inter,
    heading: nuckle,
    mono: menlo,
  },
  lineHeights: {
    normal: "normal",
  },
  letterSpacings: {
    normal: "0",
  },
  textStyles: {
    paragraph: {
      fontSize: "lg",
      my: 6,
      lineHeight: 1.6,
    },
    uppercase: {
      fontSize: "2xs",
      fontWeight: "medium",
      textTransform: "uppercase",
      letterSpacing: "0.08em",
    },
    h1: {
      fontFamily: "heading",
      fontSize: { base: "3xl", md: "4xl" },
      fontWeight: "semibold",
      lineHeight: "110%",
      m: 0,
    },
    h2: {
      fontFamily: "heading",
      fontSize: { base: "2xl", md: "3xl" },
      fontWeight: "semibold",
      lineHeight: "113%",
      mt: 12,
      mb: 6,
    },
    h3: {
      fontFamily: "heading",
      fontSize: { base: "xl", md: "2xl" },
      fontWeight: "semibold",
      lineHeight: "113%",
      mt: 12,
      mb: 6,
    },
    h4: {
      fontFamily: "heading",
      fontSize: { base: "lg", md: "xl" },
      fontWeight: "semibold",
      lineHeight: "113%",
      mt: 8,
      mb: 6,
    },
  },
};
