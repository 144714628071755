import { Text } from "@chakra-ui/react";

import { Link } from "ui/link";

export const SyncAlerts = () => (
  <Text textStyle="paragraph">
    Hightouch can alert you of sync issues via Slack, PagerDuty, SMS, or email.
    For details, please visit our{" "}
    <Link variant="inline" href="/docs/syncs/alerting">
      article on alerting
    </Link>
    .
  </Text>
);
