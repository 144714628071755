import { Icon, IconProps } from "@chakra-ui/react";

export const MenuIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M2.5 10h15M2.5 5h15M2.5 15h15"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </Icon>
);
